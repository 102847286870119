import React, { useState } from 'react';

function IframeWebLinkTester() {
    const [link, setLink] = useState("");
    console.log(link)
    return (
        <>
            <main style={{margin:20, textAlign: "center"}}>
                <h2>Test your Iframe Experience Here: </h2>
                <input style={{width: 400}} type="text" name="link" placeholder="https://yourapplink.com" onChange={(input) => setLink(input.target.value)}/>
                <div style={{margin: 20}}>
                    <iframe src={link} frameBorder="1" height="800px" width="320px"/>
                </div>
            </main>
        </>
    );
}

export default IframeWebLinkTester;
