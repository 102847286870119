import '../styles/NotreDamePrivacyPolicy.css';
function NotreDamePrivacyPolicy2() {
    return (
        <div>
            At all home games for the Notre Dame Women’s Basketball team, if Notre Dame scores 88 or more points, all game attendees will win a free Nachos from Legend’s. Game attendees can bring their email into Legend’s and redeem it for a free nachos. This offer is valid up to 7 days after the game in which Notre Dame scores 88 or more points. Guests can redeem the offer in the restaurant. Offer only valid on regular Nachos.*
            Offer includes one (1) free regular Nachos. Not valid with any other offers, discounts, or special promotions. One offer per customer per order. One time use. No cash value. Tax not included. To redeem at the restaurant, present your email to your server when ordering. Void where prohibited. Offer must be used within seven days hours after the end of the game where the promotion was activated.
        </div>
    );
}

export default NotreDamePrivacyPolicy2;
