import '../styles/NotreDameShufflePrivacyPolicy.css';

function NotreDamePrivacyPolicyShuffle() {
    return (
        <div>
            <p className="c14"><span className="c9">Notre Dame Helmet Shuffle</span><span className="c5">&nbsp;GAME (&ldquo;the Game&rdquo;) RULES </span></p>
            <p className="c20"><span className="c5">Prizes </span></p>
            <p className="c19"><span className="c2">Prizes will vary Game to Game from team &amp; sponsor/ partner rewards. See Prize Release below. </span></p>
            <p className="c11"><span className="c5">How to Play </span></p>
            <p className="c4"><span className="c2">To play shuffle game, access the Game through the link provided. Enter your email and other information and agree to the rules to register. Once registered click &ldquo;Let&rsquo;s Play&rdquo; and you will see a shuffle video play. After the video plays you will be given options to pick. Click the option and click CHOOSE to lock in your pick. Once you choose an option, you will not be able to change it. If you are one of the first participants to have the correct pick you will be sent rewards from this session. </span></p>
            <p className="c11"><span className="c5">How to Win </span></p>
            <p className="c13"><span className="c2">If your pick is correct, users will earn a prize if they have done so within the first set of players. Using the contact information provided to the Notre Dame Helmet Shuffle by the player at registration, the Notre Dame Helmet Shuffle will notify each winner by email with the rewards attached. Players must provide their true email when registering for Notre Dame Helmet Shuffle game order to be eligible for any prizes. Any change in name, whether due to marriage or otherwise, must be reflected on the registration page prior to playing in order to be eligible for any prize. Only the person identified by name as the Winner at the time the Winner is notified by email, which is supplied by the player during registration, may claim the prize from the Notre Dame Helmet Shuffle. Persons who register with fictitious names will be ineligible to win any prize. Upon email, the winner will be notified of the winning prize(s). No user may play the Shuffle game more than once in a 24 hour period. </span></p>
            <p className="c11"><span className="c5">Eligibility and Participation </span></p>
            <p className="c0"><span className="c2">Participants must be 13-years of age or older. In addition, participants must meet eligibility requirements at the time of the program drawing. Eligible minor entrants must have their parent&rsquo;s or legal guardian&rsquo;s permission to participate in this Game. To enter the Shuffle game, participants must register as a player on the Notre Dame Helmet Shuffle game website. When registering, participants must provide access to user verification &amp; information through Phone Number login in order to play. In addition, participants must provide true and current information. Failure to provide participant&rsquo;s full legal name at registration through Phone Number will make participant ineligible to receive prizes from the Notre Dame Helmet Shuffle game. In Notre Dame Helmet Shuffle game, only the person identified by name as the Winner on email at the time the Winner&rsquo;s name is posted, which is supplied by the player during registration, may claim the prize from the Notre Dame Helmet Shuffle game. Any change in name, whether due to marriage or otherwise, must be reflected on the user&rsquo;s account prior to playing in order to be</span></p>
            <p className="c3"><span className="c2">eligible for any prize. Persons who register with fictitious names or phone numbers will be ineligible to win any prize. Notre Dame Helmet Shuffle game is not open to certain Notre Dame Helmet vendors or employees of the Notre Dame Helmet Entities, their agents, and their family members residing in the same household. </span></p>
            <p className="c26"><span className="c5">Odds </span></p>
            <p className="c25"><span className="c2">The odds of winning any prize vary based on the total number of players and the total number of prizes. Players may not play, win, or receive a reward email more than once in a 24-hour period. </span></p>
            <p className="c12"><span className="c5">Additional Terms </span></p>
            <p className="c10"><span className="c2">Participants are responsible for complying with these Official Rules in addition to state and federal law and Notre Dame Helmet Shuffle Game Regulations. Proof of age, eligibility, and identity must be furnished upon request. Participants agree to the Notre Dame Helmet Shuffle use of their name, photo, likeness, and hometown for advertising and publicity purposes, without compensation. All taxes are the responsibility of the winner. By participating in the program, and/or accepting any prize or promotional award, participants agree to and do release and hold harmless the Notre Dame Helmet Shuffle and its commissioners, employees, agents, and officers from any claim, demand, judgment, award, and/or any liability of any kind whatsoever. The Notre Dame Helmet Shuffle reserves the right to modify or terminate this program at any time. In the event of a conflict, these Official Rules supersede any applicable general rules or advertisements of the Notre Dame Helmet Shuffle. </span></p>
            <p className="c12"><span className="c5">Winners Release </span></p>
            <p className="c1"><span className="c2">By playing participants hereby acknowledge receipt of prizes awarded in the Notre Dame Helmet Shuffle game sponsored by team sponsor and in doing so, declare they meet Game eligibility requirements, and hereby agree as follows: </span></p>
            <p className="c16"><span className="c2">1. Release. I hereby release, covenant not to sue, and discharge the Notre Dame Helmet Shuffle &amp; any other Game sponsor, their respective affiliates and the employees, officers, directors and agents of each (collectively, &ldquo;Releasees&rdquo;) from (a) any claim in respect of the conduct of the Game or the award of the prize, or in connection with the use of my name, photo, voice, likeness and biographical information by Releasees, including, but not limited to, </span></p>
            <p className="c23"><span className="c2">claims for libel, false light, right of privacy and right of publicity, and (b) any and all liabilities, claims, damages, costs and expenses (including attorneys&rsquo; fees) incurred for or due to taxes owed by me, or due to any personal injury to me or others, or damage to property owned by me or others which injury or damage may arise in any connection with this Game, the prize awarded or its use, and any travel, accommodations, goods or services provided or not provided to </span></p>
            <p className="c24"><span className="c2">me or others whether such injury or damage is caused by the active or passive negligence of Releasees or otherwise. </span></p>
            <p className="c15"><span className="c2">2. Indemnity. I hereby agree to indemnify and hold harmless Releasees and each of them from and against any and all liabilities, claims, damages, costs and expenses (including attorneys&rsquo; fees) incurred for or due to taxes owed by me, or due to any personal injury to, or damage to</span></p>
            <p className="c7"><span className="c2">property owned by me, members of my household or family, the agents, employees, contractors, visitors and guests of any of us, and any other persons, which injury or damage may arise in connection with this Game, the prize awarded or its use, and any travel, accommodations, goods or services </span></p>
            <p className="c18"><span className="c2">provided or not provided to me or others, whether such injury or damage is caused by the active or passive negligence of Releasees or otherwise. </span></p>
            <p className="c8"><span className="c2">3. Publicity Consent. I hereby consent to the use by the Notre Dame Helmet Shuffle and any Game co-sponsors, and their permittees, of my name, photo, voice, likeness and biographical information for promotional, advertising, marketing and other business purposes without additional consideration. </span></p>
            <p className="c22"><span className="c2">4. Valuation of Prize: I hereby acknowledge that the prices of products and services offered as Game prizes often fluctuate. I agree that the value stated in the Official Game Rules for the prize I have won reflects the reasonable, good faith estimate of the Notre Dame Helmet Shuffle &amp; any game co-sponsors. I accept and agree to be bound by this valuation for all purposes relating to the Game, including the reporting of prize values to the Internal Revenue Service (&ldquo;IRS&rdquo;). I further agree that I am not entitled to the difference between the estimated value of my prize and the actual price at which my prize may be advertised or sold in the open market at any given time. I will make no such claim against Notre Dame Helmet Shuffle or the other Releases. I acknowledge that Notre Dame Helmet Shuffle must report the award of any prizes that it values at $600 or more to the IRS as income to me and I will not demand that Notre Dame Helmet Shuffle report any amount other than the prize value stated in the Official Game Rules. </span></p>
            <p className="c27"><span className="c2">5. Voluntary Agreement: BY PLAYING I HAVE CAREFULLY READ THIS AGREEMENT AND UNDERSTAND ITS TERMS, INCLUDING THE RELEASE OF LIABILITY AND INDEMNIFICATION OF THE PARTIES NAMED HEREIN AND RELATED PERSONS AND COMPANIES. I HAVE ENTERED INTO THIS AGREEMENT VOLUNTARILY AND NOT IN RELIANCE ON ANY PROMISES OR REPRESENTATIONS OTHER THAN THOSE CONTAINED IN THIS AGREEMENT ITSELF. </span></p>
            <p className="c6"><span className="c2">These rules cover all Notre Dame Properties.</span></p>
            <p className="c28"><span className="c2"></span></p>
        </div>
    );
}

export default NotreDamePrivacyPolicyShuffle;
