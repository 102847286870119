import { Routes, Route } from "react-router-dom";
import DeleteData from "../src/components/DeleteDataPage";
import CPrivacyPolicy from "../src/components/CPrivacyPolicy";
import HartfordPrivacyPolicy from "../src/components/HartfordPrivacyPolicy";
import NotreDamePrivacyPolicy from "../src/components/NotreDamePrivacyPolicy";
import IframeWebLinkTester from "../src/components/IframeWebLinkTester";
import NotreDamePrivacyPolicy2 from "./components/NotreDamePrivacyPolicy2";
import NotreDamePrivacyPolicyShuffle from "./components/NotreDamePrivacyPolicyShuffle";
import NotreDamePrivacyPolicyBingo from "./components/NotreDamePrivacyPolicyBingo";

function App() {
  return (
      <div className="App">
        {/*<h1>Welcome to Sqwad!</h1>*/}
        <Routes>
            <Route path="deletedata" element={<DeleteData />} />
            <Route path="c_privacypolicy" element={<CPrivacyPolicy />} />
            <Route path="hartford_privacypolicy" element={<HartfordPrivacyPolicy />} />
            <Route path="notredame_privacypolicy" element={<NotreDamePrivacyPolicy />} />
            <Route path="notredame_privacypolicy2" element={<NotreDamePrivacyPolicy2 />} />
            <Route path="notredame_privacypolicy3" element={<NotreDamePrivacyPolicyShuffle />} />
            <Route path="notredame_privacypolicy4" element={<NotreDamePrivacyPolicyBingo />} />
            <Route path="iframeweblinktester" element={<IframeWebLinkTester />} />
        </Routes>
      </div>
  );
}

export default App;
