function DeleteData() {
    return (
        <>
            <main>
                <h2>Delete Data</h2>
                <p>Send your delete data requests to info@sqwadapp.co</p>
            </main>
        </>
    );
}

export default DeleteData;